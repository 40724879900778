import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { environment } from "../environments/environment";

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: "menufy-web-root",
  template: ` <router-outlet /> `,
})
export class AppComponent {
  constructor() {
    this.loadPlausibleScript();
  }

  private loadPlausibleScript() {
    const { plausible } = environment;

    if (!plausible?.src?.length) {
      return;
    }

    const script = document.createElement("script");
    script.src = plausible?.src;
    script.dataset["domain"] = plausible?.domain as string;
    script.defer = true;

    const head = document.getElementsByTagName("head").item(0) as HTMLHeadElement;
    head.appendChild(script);
  }
}
