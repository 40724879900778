import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";

@Component({
  selector: "menufy-web-splash",
  standalone: true,
  template: `
    <section class="splash-wrapper">
      <img src="/assets/icons/eateasy-text.png" alt="brand-logo" />
    </section>
  `,
  styleUrl: "./splash.scss",
})
export class SplashComponent implements OnInit {
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _router = inject(Router);
  private readonly _authService = inject(AuthService);
  private readonly _accessToken = toSignal(this._authService.getAccessTokenSilently());

  ngOnInit(): void {
    this._authService.isAuthenticated$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this._router.navigate(["/auth/login/callback"], {
            queryParams: { accessToken: this._accessToken() },
          });
        } else {
          this._loginUser();
        }
      });
  }

  private _loginUser() {
    this._authService.loginWithRedirect();
  }
}
