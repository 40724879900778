import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UserAuthService } from "../auth/services/user-auth.service";

export const authGuard: CanActivateFn = () => {
  const userAuth = inject(UserAuthService);
  const router = inject(Router);

  if (userAuth?.userData() && userAuth?.userData()?.accessToken) {
    return true;
  }

  return router.createUrlTree(["/"]);
};
