import { Routes } from "@angular/router";
import { SplashComponent } from "./splash/splash";
import { authGuard, loginGuard } from "./guards";

export default [
  {
    path: "",
    component: SplashComponent,
    title: "EatEasy-Restaurant",
    canActivate: [loginGuard],
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/routes"),
    canActivate: [loginGuard],
  },
  {
    path: "get-started",
    loadChildren: () => import("./getting-started/route"),
    canActivate: [authGuard],
  },
  {
    path: "add-business",
    loadChildren: () => import("./add-business/routes"),
    canActivate: [authGuard],
  },
  {
    path: "my-business",
    loadChildren: () => import("./my-business/routes"),
    canActivate: [authGuard],
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
] as Routes;
